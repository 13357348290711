import {SocialButton} from "../atoms/button--social";
import {DictionaryContext} from "../../context/dictionary-context";
import {useContext} from "react";
import {Dictionary} from "../../types/hygiena-types";

/**
 * A simple social media buttons components.
 *
 * @constructor
 */
export function SocialButtons () {
  const t = useContext<Dictionary>(DictionaryContext)

  return (
    <div className="text-center flex flex-col h-full justify-end">
      <div className="font-semibold text-black pb-2">{`${t?.all?.follow_us}`}</div>
      <div className="flex justify-center gap-2">
        <SocialButton alt="LinkedIn"
                      className={"social-linkedin-page"}
                      height={50}
                      width={50}
                      link={{href: "https://www.linkedin.com/company/hygiena/"}}
                      icon={{type: "linkedin", color: "primary", stroke: true}}/>
        <SocialButton alt="YouTube"
                      className={"social-youtube-page"}
                      height={50}
                      width={50}
                      link={{href: "https://www.youtube.com/user/HygienaTV"}}
                      icon={{type: "youtube", color: "primary", stroke: true}}/>
        <SocialButton alt="Twitter"
                      className={"social-twitter-page"}
                      height={50}
                      width={50}
                      link={{href: "https://twitter.com/hygienaint"}}
                      icon={{type: "twitter", color: "primary", stroke: true}}/>
      </div>
    </div>
  )
}